import React, { createContext, ReactNode } from 'react'
import { TicketType } from '@/enums'

// 型定義
type SharedData = {
  siteCode: number
  siteParam: string
}

type Props = {
  children: ReactNode
}

// デフォルト値
const sharedData: SharedData = {
  siteCode: TicketType.als,
  siteParam: '',
}

// Contextの作成
export const SharedContext = createContext<SharedData>(sharedData)

export const SharedContextProvider: React.FC<Props> = ({ children }) => {
  return (
    <SharedContext.Provider value={sharedData}>
      {children}
    </SharedContext.Provider>
  )
}
