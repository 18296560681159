import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ListMovieLink } from '@/components/parts/ListMovieLink'
import { RadioGroup } from '@/components/parts/RadioGroup'
import { Radio } from '@/components/parts/RadioGroup/Radio'
import { Button } from '@/components/parts/Button'
import { Loader } from '@/components/parts/Loader'
import { Video } from '@/models/video'
import { TicketGroup, Ticket, OptionPrice } from '@/models/ticket'
import styles from './styles.module.scss'
import { API } from '@/network/api-client'
import {
  DuplicatePurchase,
  TicketAllowType,
  SitePath,
  TicketType,
} from '@/enums'
import { PurchasedTicket } from '@/models/purchasedTicket'
import { isBeforeNow, isAfterNow } from '@/helpers/utils'

import { SharedContext } from '@/components/parts/SharedContext'

type TermsProps = {
  title: string // 許諾タイトル
  text: string
  siteCode: number
  onClick: () => void
}

const Terms: React.FC<TermsProps> = ({ title, text, siteCode, onClick }) => {
  return (
    <div
      className={`${styles.pageWrap} 
      ${siteCode === TicketType.alpha_u ? styles.alphaUcolor : ''}`}
    >
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
      </header>
      <div className={styles.linkWrap}>
        <p id="allow_view_area" dangerouslySetInnerHTML={{ __html: text }}></p>
        <button onClick={onClick}>同意して次へ</button>
      </div>
    </div>
  )
}

type SelectVideoProps = {
  videos: Video[]
  articleId: string
  ticketGroupId: number
  siteCode: number
}

const SelectVideo: React.FC<SelectVideoProps> = ({
  videos,
  articleId,
  ticketGroupId,
  siteCode,
}) => {
  const isDisabled = (video: Video): boolean => {
    if (isAfterNow(video.open)) {
      return true
    }

    if (video.close && isBeforeNow(video.close)) {
      return true
    }

    return false
  }

  const navigate = useNavigate()
  const movieClicked = useCallback(
    (video: Video) => {
      if (isAfterNow(video.open)) {
        navigate(SitePath(siteCode) + '/purchase/live-off-hours', {
          state: { ticketGroupId, articleId },
          replace: true,
        })
        return
      }

      if (video.close && isBeforeNow(video.close)) {
        navigate(SitePath(siteCode) + '/purchase/live-off-hours', {
          state: { ticketGroupId, articleId },
          replace: true,
        })
        return
      }

      window.open(
        (process.env.REACT_APP_BASE_URL ?? '') +
          SitePath(siteCode) +
          `/live/${video.id}`,
        '_parent',
        'noreferrer',
      )
    },
    [navigate, articleId, ticketGroupId],
  )

  return (
    <div>
      <header className={styles.header}>
        <h1 className={styles.title}>ライブの視聴</h1>
      </header>
      <div className={styles.listWrap}>
        {videos.map(video => (
          <ListMovieLink
            key={video.id}
            text={video.title}
            onClick={video => {
              if (video) {
                movieClicked(video)
              }
            }}
            value={video}
            disabled={isDisabled(video)}
            type="premium"
          />
        ))}
      </div>
    </div>
  )
}

type SelectTicketProps = {
  ticketGroupId: number
  tickets: Ticket[]
  options: OptionPrice[]
  articleId: string
  siteCode: number
  isFreeCoupon: boolean
}

const SelectTicket: React.FC<SelectTicketProps> = ({
  ticketGroupId,
  tickets,
  options,
  articleId,
  siteCode,
  isFreeCoupon,
}) => {
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null)
  const navigate = useNavigate()
  const purchaseButtonClicked = useCallback(
    (isFreeCoupon: boolean) => {
      navigate('/purchase/confirm', {
        state: {
          ticketGroupId,
          ticket: selectedTicket,
          options,
          articleId,
          isDirect: false,
          siteCode,
          isApplySerial: false,
          serialLockLiftTime: null, // シリアルコードロック時間
          beforeSerialCode: null, // 前回適用時に入力したシリアルコード
          serialInvalidCount: 1, // シリアルコード入力失敗回数(初期値1)
          freeCoupon: isFreeCoupon, // 無料視聴クーポン
        },
        replace: true,
      })
    },
    [navigate, selectedTicket, options, articleId, siteCode, ticketGroupId],
  )

  return (
    <div>
      <header className={styles.header}>
        <h1 className={styles.title}>ライブチケットの購入</h1>
      </header>
      <div className={styles.listWrap}>
        <RadioGroup
          onChange={value => setSelectedTicket(value)}
          value={selectedTicket}
        >
          {tickets.map(ticket => (
            <Radio key={ticket.id} value={ticket} label={ticket.title} />
          ))}
        </RadioGroup>
      </div>
      <div className={styles.linkWrap}>
        <Button
          text="視聴チケット購入へ進む"
          disabled={selectedTicket === null}
          type="toNext"
          onClick={() => {
            purchaseButtonClicked(isFreeCoupon)
          }}
        />
      </div>
    </div>
  )
}

const NoItem: React.FC = () => {
  return (
    <div className={styles.noItemPageWrap}>
      <p className={styles.noItemText}>購入可能なチケットがありません</p>
    </div>
  )
}

export const PagesSelectContents: React.FC = () => {
  const navigate = useNavigate()
  const sharedData = useContext(SharedContext)
  const query = new URLSearchParams(location.search)
  const { id } = useParams()
  const [articleId] = useState(query.get('articleId') ?? '')
  const [ticketGroup, setTicketGroup] = useState<TicketGroup | null>(null)
  const [purchasableTickets, setPurchasableTickets] = useState<Ticket[]>([])
  const [purchasedTickets, setPurchasedTickets] = useState<PurchasedTicket[]>(
    [],
  )
  const [purchasedVideos, setPurchasedVideos] = useState<Video[]>([])
  const [isAgreed, setIsAgreed] = useState(false)
  const [isSetPurchasedInfo, setIsSetPurchasedInfo] = useState(false)

  const errHandler = useCallback(() => {
    const siteCode = ticketGroup?.ticketType || 0
    const errPagePath = SitePath(siteCode) + `/purchase/error/${articleId}`
    window.open(
      (process.env.REACT_APP_BASE_URL ?? '') + errPagePath,
      '_parent',
      'noreferrer',
    )
  }, [articleId, ticketGroup?.ticketType])

  useEffect(() => {
    if (sharedData.siteParam.length === 0) {
      const siteParam: string = query.get('siteParam') ?? ''
      sharedData.siteParam = siteParam
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchTickets = async () => {
      const res1 = await API.fetchTicketList(Number(id))
      if (!res1.success) {
        throw new Error()
      }
      setTicketGroup(res1.result)

      if (res1.result !== null) {
        sharedData.siteCode = res1.result.ticketType
      }

      const res2 = await API.fetchPurchasedTickets(Number(id))
      if (!res2.success) {
        throw new Error()
      }
      setPurchasedTickets(res2.result)

      // チケット情報と、購入済みチケット情報の両方を取得してから画面更新する
      setIsSetPurchasedInfo(true)
    }

    fetchTickets().catch(() => {
      errHandler()
    })
  }, [id, errHandler])

  useEffect(() => {
    if (!isSetPurchasedInfo) {
      return
    }

    // 購入可能なチケットのみ表示する
    const updatePurchasableTickets = () => {
      if (purchasedTickets.length > 0) {
        // 重複購入不可のチケットを購入済みの場合は他のチケットは買えない
        const isNotPurchasble = purchasedTickets.some(ticket => {
          return ticket.checkSameVideo === DuplicatePurchase.disable
        })

        if (isNotPurchasble) {
          setPurchasableTickets([])
        } else {
          // 重複購入可能のチケットを購入済みの場合は、重複購入可能かつ、未購入、かつ有効なチケットのみ購入可能
          const purchasable = ticketGroup?.tickets.filter(ticket => {
            const isPurcased = purchasedTickets.some(pTicket => {
              return ticket.id === pTicket.id
            })
            return (
              ticket.enable &&
              ticket.checkSameVideo === DuplicatePurchase.able &&
              !isPurcased
            )
          })
          setPurchasableTickets(
            purchasable?.slice().sort((pre, next) => {
              return pre.id - next.id
            }) ?? [],
          )
        }
      } else {
        setPurchasableTickets(
          ticketGroup?.tickets
            .filter(ticket => {
              return ticket.enable
            })
            .slice()
            .sort((pre, next) => {
              return pre.id - next.id
            }) ?? [],
        )
      }
    }

    // 購入済のチケットから、視聴可能な動画を見つける
    const videos =
      purchasedTickets
        .flatMap(ticket => {
          return ticket.videos
        })
        .filter((video, i, self) => {
          // チケットに紐づく動画は多くても5個程度なので計算量は気にしなくていい
          return self.findIndex(e => e.id === video.id) === i
        })
        .slice()
        .sort((pre, next) => {
          return pre.id - next.id
        }) ?? []

    setPurchasedVideos(videos)
    updatePurchasableTickets()
  }, [purchasedTickets, ticketGroup?.tickets, isSetPurchasedInfo])

  if (ticketGroup === null || !isSetPurchasedInfo) {
    return <Loader isShown={true} />
  }

  const isNeedToAgree =
    ticketGroup.allowType === TicketAllowType.necessary && !isAgreed

  const isFreeCoupon = Boolean(ticketGroup.freeCoupon)

  if (isNeedToAgree) {
    if (purchasableTickets.length) {
      return (
        <Terms
          title={ticketGroup.allowTitle} // 許諾タイトル
          text={ticketGroup.allowContent}
          siteCode={ticketGroup.ticketType}
          onClick={() => {
            // チケット未購入かつ、購入可能チケットが1枚の場合、直接確認画面へ遷移する
            if (!purchasedTickets.length && purchasableTickets.length === 1) {
              const siteCode = ticketGroup?.ticketType || 0
              navigate('/purchase/confirm', {
                state: {
                  ticketGroupId: ticketGroup.id,
                  ticket: purchasableTickets[0],
                  options: ticketGroup.options,
                  articleId,
                  isDirect: true,
                  siteCode: siteCode,
                  isApplySerial: false,
                  serialLockLiftTime: null, // シリアルコードロック時間
                  beforeSerialCode: null, // 前回適用時に入力したシリアルコード
                  serialInvalidCount: 1, // シリアルコード入力失敗回数(初期値1)
                  freeCoupon: ticketGroup.freeCoupon, // 無料視聴クーポン
                },
                replace: true,
              })
            } else {
              setIsAgreed(true)
            }
          }}
        />
      )
    }
  } else {
    // チケット未購入かつ、購入可能チケットが1枚の場合、直接確認画面へ遷移する
    if (!purchasedTickets.length && purchasableTickets.length === 1) {
      const siteCode = ticketGroup?.ticketType || 0
      navigate('/purchase/confirm', {
        state: {
          ticketGroupId: ticketGroup.id,
          ticket: purchasableTickets[0],
          options: ticketGroup.options,
          articleId,
          isDirect: true,
          siteCode: siteCode,
          isApplySerial: false,
          serialLockLiftTime: null, // シリアルコードロック時間
          beforeSerialCode: null, // 前回適用時に入力したシリアルコード
          serialInvalidCount: 1, // シリアルコード入力失敗回数(初期値1)
          freeCoupon: ticketGroup.freeCoupon, // 無料視聴クーポン
        },
        replace: true,
      })
    }
  }

  if (purchasedVideos.length > 0 && purchasableTickets.length > 0) {
    return (
      <div className={styles.pageWrap}>
        <SelectVideo
          videos={purchasedVideos}
          articleId={articleId}
          ticketGroupId={ticketGroup.id}
          siteCode={ticketGroup.ticketType}
        />
        <SelectTicket
          ticketGroupId={ticketGroup.id}
          tickets={purchasableTickets}
          options={ticketGroup.options}
          articleId={articleId}
          siteCode={ticketGroup.ticketType}
          isFreeCoupon={isFreeCoupon}
        />
      </div>
    )
  } else if (purchasedVideos.length > 0) {
    return (
      <div className={styles.pageWrap}>
        <SelectVideo
          videos={purchasedVideos}
          articleId={articleId}
          ticketGroupId={ticketGroup.id}
          siteCode={ticketGroup.ticketType}
        />
      </div>
    )
  } else if (purchasableTickets.length > 0) {
    return (
      <div className={styles.pageWrap}>
        <SelectTicket
          ticketGroupId={ticketGroup.id}
          tickets={purchasableTickets}
          options={ticketGroup.options}
          articleId={articleId}
          siteCode={ticketGroup.ticketType}
          isFreeCoupon={isFreeCoupon}
        />
      </div>
    )
  }

  return <NoItem />
}
