import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react'
import { Loader } from '@/components/parts/Loader'
import { GeneralContent } from '@/components/confirm/GeneralContent'
import { MembershipContent } from '@/components/confirm/MembershipContent'
import { MembershipOnly } from '@/components/confirm/MembershipOnly'
import back from '@/assets/icon/icn-back.svg'
import styles from './styles.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { OptionPrice, Ticket } from '@/models/ticket'
import { API } from '@/network/api-client'
import { MemberType, PriceMemberType, TicketType, SitePath } from '@/enums'
import dayjs, { Dayjs } from 'dayjs'
import { UserSetting } from '@/models/userSetting'
import { SharedContext } from '@/components/parts/SharedContext'

export function PageConfirm(): JSX.Element {
  const navigate = useNavigate()
  const ticketData = useLocation().state as {
    ticketGroupId: number
    ticket: Ticket
    options: OptionPrice[]
    articleId: string
    isDirect: boolean
    siteCode: number
    // クーポンコードを適用したかどうか
    isApplySerial: boolean
    // シリアルコードロック時間
    serialLockLiftTime: Dayjs | null
    // 前回適用時に入力したシリアルコード
    beforeSerialCode: string | null
    // クーポンコード入力失敗回数
    serialInvalidCount: number
    // メールマガジン
    promotionMail?: boolean | null
    // 無料視聴クーポン
    freeCoupon: number
  }
  const [memberType, setMemberType] = useState<number | null>(null)
  const [userSetting, setUserSetting] = useState<UserSetting | null>(null)
  const [optionTotal] = useState(() => {
    return ticketData.options.reduce((prev, current) => {
      return prev + current.optionPrice
    }, 0)
  })
  /** ローディング表示 */
  const [isLoading, setIsLoading] = useState(false)
  /** クーポンコード */
  const serialCode = useRef<string | null>(null)
  /** クーポンコードを適用 & クーポンコードの有効・無効 */
  const [serialCodeStatus, setSerialCodeStatus] = useState({
    isApply: false,
    invalid: false,
  })
  /** クーポン割引 */
  const [discount, setDiscount] = useState({
    type: 0,
    text: 'クーポン割引',
    amount: 0,
  })
  /** クーポンコードロック */
  const [serialCodeLock, setSerialCodeLock] = useState(false)
  /** クーポンコード失敗上限(5回目でロックをかける) */
  const SERIAL_INVALID_LIMIT = 6
  /** ロック時間(秒設定) */
  const LOCK_TIME = 60
  /** APIから受け取ったクーポンコードのエラーメッセージ */
  const [couponErrorMessage, setCouponErrorMessage] = useState(
    '※ 入力されたクーポンコードは有効ではありません',
  )

  const sitePath = SitePath(ticketData.siteCode)

  // 入会ページのパラメータ
  const sharedData = useContext(SharedContext)
  const siteParam: string = sharedData.siteParam

  const errPageUrl =
    (process.env.REACT_APP_BASE_URL ?? '') +
    sitePath +
    `/purchase/error/${ticketData.articleId}`

  const errHandler = useCallback(() => {
    window.open(errPageUrl, '_parent', 'noreferrer')
  }, [errPageUrl])

  const openSmartPassPage = useCallback(() => {
    const articlePath = sitePath + `/live/${ticketData.articleId}`
    const targetUrl = encodeURIComponent(
      (process.env.REACT_APP_TOP_PAGE_URL ?? '') + articlePath,
    )

    const baseUrl = process.env.REACT_APP_SMARTPASS_URL ?? ''
    const queryParams = `&serial=${ticketData.articleId}&ru=${targetUrl}`
    const openUrl = siteParam
      ? `${baseUrl}${queryParams}&rid=${encodeURIComponent(siteParam)}`
      : `${baseUrl}${queryParams}`

    window.open(openUrl, '_parent', 'noreferrer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData, sitePath])

  const openVideoPassPage = useCallback(() => {
    let articlePath = sitePath + `/live/${ticketData.articleId}`
    if (ticketData.siteCode === TicketType.telasa) {
      articlePath += `?medid=telasa&serial=carousel&srcid=${ticketData.articleId}`
    }
    if (ticketData.siteCode === TicketType.alpha_u) {
      articlePath += `?medid=alpha-u&serial=carousel&srcid=${ticketData.articleId}`
    }
    const targetUrl = encodeURIComponent(
      (process.env.REACT_APP_TOP_PAGE_URL ?? '') + articlePath,
    )

    window.open(
      (process.env.REACT_APP_VIDEOPASS_URL ?? '') +
        `${targetUrl}` +
        `&medid=telasa_live&serial=purchase&srcid=${ticketData.articleId}`,
      '_parent',
      'noreferrer',
    )
  }, [ticketData, sitePath])

  // 無料視聴クーポン判定フラグ
  const isFreeCoupon = Boolean(ticketData.freeCoupon)

  // 決済方法選択画面へ遷移
  const payment = async (
    pid: number,
    isFreeCoupon: boolean,
    isMembership: boolean,
    generalPrice?: number,
    membershipPrice?: number,
    promotionMail?: boolean,
  ) => {
    const price = isMembership ? Number(membershipPrice) : Number(generalPrice)
    // クーポンコードが入力されている & 適用していない
    if (!!serialCode.current && !ticketData.isApplySerial) {
      let isSerialInvalid = false
      // eslint-disable-next-line
      await serialCodeApply()
        .then(status => {
          isSerialInvalid = status
        })
        .catch(() => {
          errHandler()
        })
      // 無料視聴クーポン以外の場合は割引金額の確認のためリターン
      if (!isFreeCoupon) {
        return
      } else {
        // 無料視聴クーポンかつクーポンコードが無効の場合は抜ける
        if (isSerialInvalid) {
          return
        }
      }
    }

    if (isFreeCoupon) {
      // 無料視聴クーポンの確定を行う
      // eslint-disable-next-line
      couponButtonClicked(
        ticketData.ticket.id,
        pid,
        ticketData.ticket.title,
        promotionMail,
      )
    } else if (optionTotal + price - discount.amount <= 0) {
      // 決済金額が0円の場合
      // eslint-disable-next-line
      purchaseButtonClicked(
        ticketData.ticket.id,
        pid,
        ticketData.ticket.title,
        promotionMail,
      )
    } else if (ticketData.siteCode === TicketType.telasa) {
      // TELASAの場合は、コンビニ決済が選択出来るとマズイので、かんたん決済のみ行う
      // eslint-disable-next-line
      purchaseButtonClicked(
        ticketData.ticket.id,
        pid,
        ticketData.ticket.title,
        promotionMail,
      )
    } else {
      // Stripe審査が通っていない為、決済金額が0以上 && au Live Streamingでも直接かんたん決済を行う
      // 決済金額が0円以上の場合かつ、au Live Streamingの場合は、決済方法選択画面へ進む
      // 転送先URL
      const url = '/purchase/payment'
      navigate(url, {
        state: {
          ticketGroupId: ticketData.ticketGroupId,
          ticket: ticketData.ticket,
          options: ticketData.options,
          articleId: ticketData.articleId,
          isDirect: ticketData.isDirect,
          siteCode: ticketData.siteCode,
          serialCode: serialCode.current, // 入力したシリアルコード
          serialLockLiftTime: null, // シリアルコードロック時間
          beforeSerialCode: ticketData.beforeSerialCode, // 前回適用時に入力したシリアルコード
          serialInvalidCount: 1, // シリアルコード入力失敗回数(初期値1)
          pid: pid, // PriceレコードのID
          isMembership: isMembership, // 会員コンテンツか
          generalPrice: generalPrice, // 一般価格
          membershipPrice: membershipPrice, // 会員価格
          optionTotal: optionTotal, // システム手数料計
          discountTitle: discount.text, // 割引名
          discountPrice: discount.amount, // 割引金額
          name: '', // コンビニ決済用の名前
          mail: '', // コンビニ決済用のメールアドレス
          promotionMail: promotionMail, // メールマガジン
          freeCoupon: ticketData.freeCoupon, // 無料視聴クーポン
          backPageUrl: '/purchase/confirm', // 前画面に戻るためのURL(現在のページのURL)
        },
      })
      // -----------------------------------------------
    }
  }

  // かんたん決済
  const purchaseButtonClicked = useCallback(
    (
      ticketId: number,
      priceId: number,
      commodity: string,
      promotionMail?: boolean | null,
    ) => {
      // 簡単決済のシステムでトランザクションIDを発行したのち、システム側のページに遷移させる
      const ticketAuthory = async () => {
        const completePageUrl =
          (process.env.REACT_APP_BASE_URL ?? '') +
          sitePath +
          '/purchase/complete'

        const res = await API.purhcaseTicketAuthory(
          ticketId,
          priceId,
          commodity,
          completePageUrl,
          errPageUrl,
          Number(ticketData.articleId),
          // クーポンコード
          serialCode.current,
          // 名前（コンビニ決済用）
          null,
          // メールアドレス（コンビニ決済用）
          null,
          // 個人情報
          null,
          // メールマガジン
          promotionMail,
        )

        if (!res.success) {
          errHandler()
          return
        }

        window.open(res.result.redirectUrl, '_parent', 'noreferrer')
      }

      ticketAuthory().catch(() => {
        errHandler()
      })
    },
    [ticketData, errHandler, errPageUrl, sitePath],
  )

  // 無料視聴クーポンの適用処理
  const couponButtonClicked = useCallback(
    (
      ticketId: number,
      priceId: number,
      commodity: string,
      promotionMail?: boolean | null,
    ) => {
      // 簡単決済のシステムでトランザクションIDを発行したのち、システム側のページに遷移させる
      const TicketCoupon = async () => {
        const completePageUrl =
          (process.env.REACT_APP_BASE_URL ?? '') +
          sitePath +
          '/purchase/complete'

        const res = await API.purhcaseTicketCoupon(
          ticketId,
          priceId,
          commodity,
          completePageUrl,
          errPageUrl,
          Number(ticketData.articleId),
          // クーポンコード
          serialCode.current,
          // 個人情報
          null,
          // メールマガジン
          promotionMail,
        )

        if (!res.success) {
          errHandler()
          return
        }

        window.open(res.result.redirectUrl, '_parent', 'noreferrer')
      }

      setIsLoading(true)
      TicketCoupon().catch(() => {
        setIsLoading(false)
        errHandler()
      })
    },
    [ticketData, errHandler, errPageUrl, sitePath],
  )

  useEffect(() => {
    const fetchMemberType = async () => {
      const res = await API.fetchMemberType(ticketData.siteCode)
      setMemberType(res.type)
    }

    fetchMemberType().catch(() => {
      window.open(errPageUrl, '_parent', 'noreferrer')
    })

    const fetchUserSetting = async () => {
      const res = await API.fetchUserSetting(ticketData.siteCode)
      setUserSetting(res.setting)
    }
    fetchUserSetting().catch(() => {
      window.open(errPageUrl, '_parent', 'noreferrer')
    })
  }, [ticketData, errPageUrl])

  /**
   * クーポンコード適用処理
   */
  const serialCodeApply = async (): Promise<boolean> => {
    ticketData.beforeSerialCode = serialCode.current
    // 現在時刻取得
    const now = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss'))
    // 失敗時間が存在しない場合はfalse、失敗時間が存在し、現在時刻との差がロック時間未満の場合はtrue、以上はfalse
    const isTryError = () => {
      if (ticketData.serialLockLiftTime) {
        if (now.diff(ticketData.serialLockLiftTime) / 1000 < LOCK_TIME) {
          return true
        }
      }
      return false
    }

    // 失敗時間との差がロック時間未満の場合は、リターン
    if (isTryError()) {
      // クーポンコードロック
      setSerialCodeLock(true)
      return true
    }
    // 失敗時間が存在する場合は、失敗時間と回数をリセット & ロック解除
    if (!isTryError() && ticketData.serialLockLiftTime) {
      // クーポンコードロック
      setSerialCodeLock(false)
      ticketData.serialLockLiftTime = null
      ticketData.serialInvalidCount = 1
    }

    setIsLoading(true)
    try {
      const res = await API.verifySerialCode(
        // チケットID
        ticketData.ticket.id,
        // クーポンコード
        serialCode.current as string,
      )

      if (res.result) {
        // クーポンコード有効
        if (res.result.isValid) {
          setSerialCodeStatus({ isApply: true, invalid: false })
          // APIから受け取った割引金額をセット
          setDiscount({ ...discount, amount: res.result.serialDiscount })
          // クーポンコード適用済みにする
          ticketData.isApplySerial = true
        } else {
          // APIからエラーメッセージを受け取り、画面に表示する
          if (res.result.reason) {
            setCouponErrorMessage(res.result.reason)
          }
          // クーポンコード無効
          setSerialCodeStatus({ isApply: true, invalid: true })
          // 割引金額をリセット
          setDiscount({ ...discount, amount: 0 })
          // クーポン不正回数加算
          ticketData.serialInvalidCount++
          // クーポンコードの失敗上限を超える
          if (ticketData.serialInvalidCount === SERIAL_INVALID_LIMIT) {
            // 失敗時刻に現在時刻を設定
            ticketData.serialLockLiftTime = now
            // 5回失敗した時点で、ロックされています文言を表示する
            setSerialCodeLock(true)
          }
        }
      }
      setIsLoading(false)
      return !res.result.isValid
    } catch {
      errHandler()
      setIsLoading(false)
      return true
    }
  }

  // 決済に進む際の有効・無効切り替え
  const preventPurchase = (
    termsChecked: boolean,
    isShowSerialCode: boolean,
    current: string | null,
    isApply: boolean,
    invalid: boolean,
    isFreeCoupon: boolean,
  ) => {
    // 利用規約に同意していない場合は、決済に進めない
    if (!termsChecked) return true
    // 無料視聴クーポンの場合はクーポンコード必須
    if (isFreeCoupon && (current === null || current.length === 0)) {
      return true
    }
    // クーポンが不正な状態で適用クリック後、クーポンを変更した場合、決済画面に進めるようにする
    if (current && serialCode.current !== ticketData.beforeSerialCode) {
      return false
    }
    // クーポンコード入力画面が表示され、クーポンコードを入力し、適用済みでクーポンが無効な場合は、決済に進めない
    if (isShowSerialCode && current && isApply && invalid) {
      return true
    }
    return false
  }

  // 送付先情報入力(個人情報取得)画面に遷移
  const moveToPersonalData = async (
    pid: number,
    price: number,
    // 会員コンテンツか
    isMembership: boolean,
    // 一般価格
    generalPrice?: number,
    // 会員価格
    membershipPrice?: number,
    // メールマガジン
    promotionMail?: boolean,
    // 無料視聴クーポン
    isFreeCoupon?: boolean,
  ) => {
    // --------------------------------------------
    // クーポンコードが入力されている & 適用していない
    if (!!serialCode.current && !ticketData.isApplySerial) {
      let isSerialInvalid = false
      // eslint-disable-next-line
      await serialCodeApply()
        .then(status => {
          isSerialInvalid = status
        })
        .catch(() => {
          errHandler()
        })
      // 無料視聴クーポン以外の場合は割引金額の確認のためリターン
      if (!isFreeCoupon) {
        return
      } else {
        // 無料視聴クーポンかつクーポンコードが無効の場合は抜ける
        if (isSerialInvalid) {
          return
        }
      }
    }
    // 遷移先URL
    const url = '/purchase/personal-data'
    navigate(url, {
      state: {
        ticketGroupId: ticketData.ticketGroupId,
        ticket: ticketData.ticket,
        options: ticketData.options,
        articleId: ticketData.articleId,
        isDirect: ticketData.isDirect,
        siteCode: ticketData.siteCode,
        serialCode: serialCode.current, // 入力したシリアルコード
        serialLockLiftTime: null, // シリアルコードロック時間
        beforeSerialCode: ticketData.beforeSerialCode, // 前回適用時に入力したシリアルコード
        serialInvalidCount: 1, // シリアルコード入力失敗回数(初期値1)
        pid: pid, // PriceレコードのID
        price: price, // チケット金額
        isMembership: isMembership, // 会員コンテンツか
        generalPrice: generalPrice, // 一般価格
        membershipPrice: membershipPrice, // 会員価格
        // --------------------------------------------
        optionTotal: optionTotal, // システム手数料計
        discountTitle: discount.text, // 割引名
        discountPrice: discount.amount, // 割引金額
        formName: '', // 入力フォームのお名前
        formTel: '', // 入力フォームの電話番号
        formMail: '', // 入力フォームのメールアドレス
        formConfirmationMail: '', // 入力フォームのメールアドレス(確認)
        formPostalCode: '', // 入力フォームの郵便番号
        formAddress: '', // 入力フォームの住所
        promotionMail: promotionMail, // メールマガジン
        freeCoupon: ticketData.freeCoupon, // 無料視聴クーポン
      },
      replace: true,
    })
  }

  // チケット種別（up or telasa or alpha-u）、会員種別でチケット購入情報の表示が変わる
  const PriceList = () => {
    // TELASA チケット
    if (ticketData.siteCode === TicketType.telasa) {
      const generalItem = ticketData.ticket.prices.find(price => {
        return price.memberType === PriceMemberType.telasaGeneral
      })

      const videoPassItem = ticketData.ticket.prices.find(price => {
        return price.memberType === PriceMemberType.videoPass
      })

      if (memberType === MemberType.telasaGeneral && !generalItem) {
        return (
          <MembershipOnly
            siteCode={ticketData.siteCode}
            joinAction={openVideoPassPage}
          />
        )
      }

      if (memberType === MemberType.videoPass && videoPassItem) {
        return (
          <>
            {/* クーポンコード照会時に、ローディングを表示する */}
            <Loader isShown={isLoading} />
            {!isLoading && (
              <MembershipContent
                ticketTitle={ticketData.ticket.title}
                generalPrice={generalItem?.price}
                membershipPrice={videoPassItem.price}
                ticket={ticketData.ticket}
                options={ticketData.options}
                optionTotal={optionTotal}
                siteCode={ticketData.siteCode}
                // 決済方法選択画面へ遷移
                purhcaseAction={async (promotionMail?: boolean) => {
                  await payment(
                    videoPassItem.id,
                    isFreeCoupon,
                    true,
                    generalItem?.price,
                    videoPassItem.price,
                    promotionMail,
                  )
                }}
                // クーポンヘッダIDが存在する場合は、クーポンコード入力画面を表示する
                isShowSerialCode={!!ticketData.ticket.serialHeaderId}
                serialCode={serialCode}
                serialCodeApply={async () => {
                  await serialCodeApply()
                }}
                serialCodeStatus={serialCodeStatus}
                discount={discount}
                serialCodeLock={serialCodeLock}
                // 決済ボタンの制御関数
                preventPurchase={(
                  termsChecked: boolean,
                  isShowSerialCode: boolean,
                  current: string | null,
                  isApply: boolean,
                  invalid: boolean,
                  isFreeCoupon: boolean,
                ) =>
                  preventPurchase(
                    termsChecked,
                    isShowSerialCode,
                    current,
                    isApply,
                    invalid,
                    isFreeCoupon,
                  )
                }
                // クーポン不正・使用済みの場合の文言
                couponErrorMessage={couponErrorMessage}
                // 送付先情報入力(個人情報取得)画面
                moveToPersonalData={(promotionMail?: boolean) => {
                  // eslint-disable-next-line
                  moveToPersonalData(
                    videoPassItem.id,
                    videoPassItem.price,
                    true,
                    generalItem?.price,
                    videoPassItem.price,
                    promotionMail,
                    isFreeCoupon,
                  )
                  // --------------------------------------------
                }}
                promotionMail={ticketData.promotionMail}
                promotionMailAccepted={userSetting?.promotion_mail ?? false}
              />
            )}
          </>
        )
      }

      if (generalItem) {
        return (
          <>
            {/* クーポンコード照会時に、ローディングを表示する */}
            <Loader isShown={isLoading} />
            {!isLoading && (
              <GeneralContent
                ticketTitle={ticketData.ticket.title}
                price={generalItem.price}
                ticket={ticketData.ticket}
                priceDiff={
                  videoPassItem !== undefined
                    ? generalItem.price - videoPassItem.price
                    : 0
                }
                hasMembershipItem={videoPassItem !== undefined}
                options={ticketData.options}
                optionTotal={optionTotal}
                siteCode={ticketData.siteCode}
                // 決済方法選択画面へ遷移
                purhcaseAction={async (promotionMail?: boolean) => {
                  await payment(
                    generalItem.id,
                    isFreeCoupon,
                    false,
                    generalItem.price,
                    videoPassItem?.price,
                    promotionMail,
                  )
                }}
                joinAction={openVideoPassPage}
                // シリアルヘッダIDが存在する場合は、クーポンコード入力画面を表示する
                isShowSerialCode={!!ticketData.ticket.serialHeaderId}
                serialCode={serialCode}
                serialCodeApply={async () => {
                  await serialCodeApply()
                }}
                serialCodeStatus={serialCodeStatus}
                discount={discount}
                serialCodeLock={serialCodeLock}
                // 決済ボタンの制御関数
                preventPurchase={(
                  termsChecked: boolean,
                  isShowSerialCode: boolean,
                  current: string | null,
                  isApply: boolean,
                  invalid: boolean,
                  isFreeCoupon: boolean,
                ) =>
                  preventPurchase(
                    termsChecked,
                    isShowSerialCode,
                    current,
                    isApply,
                    invalid,
                    isFreeCoupon,
                  )
                }
                // クーポン不正・使用済みの場合の文言
                couponErrorMessage={couponErrorMessage}
                // 送付先情報入力(個人情報取得)画面
                moveToPersonalData={(promotionMail?: boolean) => {
                  // eslint-disable-next-line
                  moveToPersonalData(
                    generalItem.id,
                    generalItem.price,
                    false,
                    generalItem.price,
                    videoPassItem?.price,
                    promotionMail,
                    isFreeCoupon,
                  )
                  // --------------------------------------------
                }}
                isFreeCoupon={isFreeCoupon}
                promotionMail={ticketData.promotionMail}
                promotionMailAccepted={userSetting?.promotion_mail}
              />
            )}
          </>
        )
      }

      return <></>
    }

    // upチケット
    const generalItem = ticketData.ticket.prices.find(price => {
      return price.memberType === PriceMemberType.general
    })

    const premiumItem = ticketData.ticket.prices.find(price => {
      return price.memberType === PriceMemberType.premium
    })

    if (memberType === MemberType.general && !generalItem) {
      return (
        <MembershipOnly
          siteCode={ticketData.siteCode}
          joinAction={openSmartPassPage}
        />
      )
    }

    if (memberType === MemberType.premium && premiumItem) {
      return (
        <>
          {/* クーポンコード照会時に、ローディングを表示する */}
          <Loader isShown={isLoading} />
          {!isLoading && (
            <MembershipContent
              ticketTitle={ticketData.ticket.title}
              generalPrice={generalItem?.price}
              membershipPrice={premiumItem.price}
              ticket={ticketData.ticket}
              options={ticketData.options}
              optionTotal={optionTotal}
              siteCode={ticketData.siteCode}
              // 決済方法選択画面へ遷移
              purhcaseAction={async (promotionMail?: boolean) => {
                await payment(
                  premiumItem.id,
                  isFreeCoupon,
                  true,
                  generalItem?.price,
                  premiumItem.price,
                  promotionMail,
                )
              }}
              // シリアルヘッダIDが存在する場合は、クーポンコード入力画面を表示する
              isShowSerialCode={!!ticketData.ticket.serialHeaderId}
              serialCode={serialCode}
              serialCodeApply={async () => {
                await serialCodeApply()
              }}
              serialCodeStatus={serialCodeStatus}
              discount={discount}
              serialCodeLock={serialCodeLock}
              // 決済ボタンの制御関数
              preventPurchase={(
                termsChecked: boolean,
                isShowSerialCode: boolean,
                current: string | null,
                isApply: boolean,
                invalid: boolean,
                isFreeCoupon: boolean,
              ) =>
                preventPurchase(
                  termsChecked,
                  isShowSerialCode,
                  current,
                  isApply,
                  invalid,
                  isFreeCoupon,
                )
              }
              // クーポン不正・使用済みの場合の文言
              couponErrorMessage={couponErrorMessage}
              // 送付先情報入力(個人情報取得)画面
              moveToPersonalData={(promotionMail?: boolean) => {
                // eslint-disable-next-line
                moveToPersonalData(
                  premiumItem.id,
                  premiumItem.price,
                  true,
                  generalItem?.price,
                  premiumItem.price,
                  promotionMail,
                  isFreeCoupon,
                )
                // --------------------------------------------
              }}
              promotionMail={ticketData.promotionMail}
              promotionMailAccepted={userSetting?.promotion_mail}
            />
          )}
        </>
      )
    }

    if (generalItem) {
      return (
        <>
          {/* クーポンコード照会時に、ローディングを表示する */}
          <Loader isShown={isLoading} />
          {!isLoading && (
            <GeneralContent
              ticketTitle={ticketData.ticket.title}
              price={generalItem.price}
              ticket={ticketData.ticket}
              priceDiff={
                premiumItem !== undefined
                  ? generalItem.price - premiumItem.price
                  : 0
              }
              hasMembershipItem={premiumItem !== undefined}
              options={ticketData.options}
              optionTotal={optionTotal}
              siteCode={ticketData.siteCode}
              // 決済方法選択画面へ遷移
              purhcaseAction={async (promotionMail?: boolean) => {
                await payment(
                  generalItem.id,
                  isFreeCoupon,
                  false,
                  generalItem.price,
                  premiumItem?.price,
                  promotionMail,
                )
              }}
              joinAction={openSmartPassPage}
              // シリアルヘッダIDが存在する場合は、クーポンコード入力画面を表示する
              isShowSerialCode={!!ticketData.ticket.serialHeaderId}
              serialCode={serialCode}
              serialCodeApply={async () => {
                await serialCodeApply()
              }}
              serialCodeStatus={serialCodeStatus}
              discount={discount}
              serialCodeLock={serialCodeLock}
              // 決済ボタンの制御関数
              preventPurchase={(
                termsChecked: boolean,
                isShowSerialCode: boolean,
                current: string | null,
                isApply: boolean,
                invalid: boolean,
                isFreeCoupon: boolean,
              ) =>
                preventPurchase(
                  termsChecked,
                  isShowSerialCode,
                  current,
                  isApply,
                  invalid,
                  isFreeCoupon,
                )
              }
              // クーポン不正・使用済みの場合の文言
              couponErrorMessage={couponErrorMessage}
              // 送付先情報入力(個人情報取得)画面
              moveToPersonalData={(promotionMail?: boolean) => {
                // eslint-disable-next-line
                moveToPersonalData(
                  generalItem.id,
                  generalItem.price,
                  false,
                  generalItem.price,
                  premiumItem?.price,
                  promotionMail,
                  isFreeCoupon,
                )
                // --------------------------------------------
              }}
              isFreeCoupon={isFreeCoupon}
              promotionMail={ticketData.promotionMail}
              promotionMailAccepted={userSetting?.promotion_mail}
            />
          )}
        </>
      )
    }

    return <></>
  }

  if (memberType === null) {
    return <Loader isShown={true} />
  }

  return (
    <div className={styles.pageWrap}>
      <header className={styles.header}>
        {!ticketData.isDirect && (
          <button
            className={styles.toBack}
            onClick={() =>
              navigate(
                `/purchase/select/${ticketData.ticketGroupId}?articleId=${ticketData.articleId}`,
                { replace: true },
              )
            }
          >
            <img src={back} />
          </button>
        )}
        <h1 className={styles.title}>
          {isFreeCoupon ? 'クーポンコードの入力' : 'チケットの購入'}
        </h1>
      </header>
      <div id="contentArea">
        <PriceList />
      </div>
    </div>
  )
}
